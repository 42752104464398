import {Col} from 'reactstrap';
import {CommonPath, Weaccept} from '../../../../Components/Constant';
import Image from "next/image";
import Link from 'next/link';
const SubFooter = () => {
    return (
        <Col md='8'>
            <ul>
                <li className='font-dark'>©2024. KOA Electronics Distribution LLC All Rights Reserved</li>
                <li>
                    <Link href="/privacy-and-policy" className="nav-link">
                        Privacy
                    </Link>
                </li>
                <li>
                    <Link href="/terms-of-use" className="nav-link">
                        Terms of Use
                    </Link>
                </li>
                <li>
                    <Link href="/accessibility" className="nav-link">
                        Accessibility
                    </Link>
                </li>
            </ul>
        </Col>
    );
};
export default SubFooter;
