import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../ReduxToolkit/Slices/LoginSlice";

import HeaderSearch from "./components/HeaderSearch";
import HeaderLeft from "./components/HeaderLeft";
import LoggedUser from "./components/LoggedUser";
import NavBar from "../../Element/NavBar";
import ItemCart from "../../Element/ItemCart";

const NewHeader = ({ mainMenu }) => {
  const auth = useSelector(selectAuth);
  const [isCardOpen, setIsOpenCard] = useState(false);
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleToggleCard = () => {
    setIsOpenCard(prevState => !prevState)
  };

  const handleChangeOpenCard = (isOpen) => {
    setIsOpenCard(isOpen)
  };

  return (
    <header className="new__header">
      <HeaderLeft mainMenu={mainMenu} />
      <div className="right__wrapper">
        <HeaderSearch mainMenu={mainMenu} />
        {
          isClient && auth && <button className="login__button" onClick={handleToggleCard}>Login</button>
        }
        <div className="nav__user__wrapper">
          <nav style={{
            marginLeft: '29px'
          }}> 
              <NavBar mainMenu={mainMenu} />
          </nav>
          {
            isClient && <LoggedUser handleToggleCard={handleToggleCard} handleChangeOpenCard={handleChangeOpenCard} isCardOpen={isCardOpen} />
          }
       </div>
        {
          !auth && isClient && <div className="basket__wrapper">
            <ItemCart/>
          </div>
        }
      </div>
    </header>
  )
};

export default NewHeader;