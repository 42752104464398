import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";

import { selectLoginToken } from "../../../../ReduxToolkit/Slices/LoginSlice";
import SearchForVegitable from "../../../Element/SearchForVegitable";
import SearchBarWithBgColor from "../../../Element/SearchBarWithBgColor";
import SearchBarToggle from "../../../Element/SearchBarToggle";

const HeaderSearch = () => {
  const divRef = useRef(null);
  const [productData, setProductData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [onInputText, setOnInputText] = useState('');
  const [timer, setTimer] = useState(null);
  const loginToken = useSelector(selectLoginToken);
  const router = useRouter();
  const dispatch = useDispatch();
  const {Is_Focus, Is_Search} = useSelector((state) => state.CommonReducer);

  const handleChangeInput = (value) => {
    setOnInputText(value)
  }

  const makeAPICall = async (searchQuery) => {
    if (searchQuery.length > 1) {
        const apiUrl = `https://search.koaedi.com/search_api.php?s=${searchQuery}&json=true`;

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: `Bearer ${loginToken.token || ""}`
                }
            });
            const data = await response.json();
            setProductData(data?.products?.data);
            setBrandsData(data?.brands);
            setCategoriesData(data?.categories);

        } catch (error) {
            console.error('Error making API call:', error);
        }
      }
  };

  const handleKeyPress = (e) => {
    if ((router.query.s?.toLowerCase() === onInputText.toLowerCase()) && (e.key === 'Enter')) {
        dispatch({type: 'IS_FOCUS', payload: false});
        dispatch({type: 'IS_SEARCH', payload: false});
    } else if (e.key === 'Enter') {
        router.push(`/shop?s=${onInputText}`);
        localStorage.setItem('isShop', 1)
    }
  };

  const handleKeyUp = () => {
    if (router.query.s?.toLowerCase() !== onInputText.toLowerCase()) {
      dispatch({type: 'IS_FOCUS', payload: true});
      if (timer) {
          clearTimeout(timer);
      }
      setTimer(
          setTimeout(() => {
              makeAPICall(onInputText);
          }, 500)
      );
    }
  };

  const handleOutsideClick = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
        dispatch({type: 'IS_FOCUS', payload: false});
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="header__search__wrapper">
        <SearchForVegitable 
          productData={productData}
          brandsData={brandsData}
          categoriesData={categoriesData} 
          Is_Focus={Is_Focus}
          onInputText={onInputText}
          divRef={divRef}
          handleKeyPress={handleKeyPress}
          setOnInputText={handleChangeInput}
          handleKeyUp={handleKeyUp}
        />
        <div>
          <SearchBarToggle
            productData={productData}
            brandsData={brandsData}
            categoriesData={categoriesData} 
            Is_Search={Is_Search}
            onInputText={onInputText}
            divRef={divRef}
            handleKeyPress={handleKeyPress}
            setOnInputText={handleChangeInput}
            handleKeyUp={handleKeyUp}
            Is_Focus={Is_Focus}
          />
          <SearchBarWithBgColor customeClass={'mobile__search__icon d-lg-none d-block'}/>
          {Is_Search && <div className="overlay"/>}
        </div>
    </div>
  )
};

export default HeaderSearch;