import React, { useEffect, useRef } from 'react';
import {Search} from 'react-feather';
import {useDispatch} from 'react-redux';
import SearchSuggestion from './SearchSuggestion';
import {useRouter} from "next/router";

const SearchForVegitable = ({
    productData,
    brandsData,
    categoriesData,
    Is_Focus,
    onInputText,
    divRef, handleKeyPress,
    loading, setOnInputText, handleKeyUp
}) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const searchWrapperRef = useRef(null);

    useEffect(() => {
        if(!onInputText && router.query?.s) {
            setOnInputText(router.query.s);
        }
    }, []);

    return (
        <div className={`search-box1 ${onInputText.length > 1 && Is_Focus ? 'show' : ''}`}
             onClick={() => dispatch({type: 'IS_FOCUS', payload: true})}
        >
            <div ref={divRef}>
               
                <div ref={searchWrapperRef} className="search__wrapper">
                    <input 
                        onKeyPress={handleKeyPress}
                        value={onInputText}
                        onKeyUp={handleKeyUp}
                        onChange={(e) => setOnInputText(e.target.value)}
                        placeholder="Search a Product" 
                        type="text"
                        onFocus={() => {
                            searchWrapperRef.current.style.position = 'relative';
                            searchWrapperRef.current.style.zIndex = 10;
                        }}
                    />
                    <div className="search__icon__wrapper">
                        {/* <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9.5" cy="9.5" r="8.4" stroke="white" stroke-width="2.2"/>
                            <path d="M23.5394 21.9003C24.021 22.2706 24.7116 22.1804 25.0819 21.6988C25.4522 21.2172 25.362 20.5266 24.8804 20.1563L23.5394 21.9003ZM15.6995 15.872L23.5394 21.9003L24.8804 20.1563L17.0405 14.128L15.6995 15.872Z" fill="white"/>
                        </svg> */}

                        <button
                            disabled={onInputText.length <= 1}
                            onClick={() =>
                                router.query.s?.toLowerCase() === onInputText.toLowerCase() ?
                                    setTimeout(() => {
                                        dispatch({type: 'IS_FOCUS', payload: false})
                                    }, 200) :
                                    onInputText.length > 1 && router.push(`/shop/?s=${onInputText}`)
                            }>
                            <Search/>
                        </button>   
                    </div>
                </div>
                <SearchSuggestion
                    productData={productData} brandsData={brandsData} categoriesData={categoriesData}
                    onInputText={onInputText} loading={loading}
                    divRef={divRef} Is_Focus={Is_Focus}
                />
            </div>
        </div>
    );
};

 {/* <div className='the-basics input-group' style={{zIndex: "10"}}>
                    <input type='text' className='form-control typeahead' placeholder='Search a Product'
                           onKeyPress={handleKeyPress}
                           value={onInputText}
                           onKeyUp={handleKeyUp}
                           onChange={(e) => setOnInputText(e.target.value)}
                    />
                    <button className='input-group-text close-search theme-bg-color search-box'
                            disabled={onInputText.length <= 1}
                            onClick={() =>
                                router.query.s?.toLowerCase() === onInputText.toLowerCase() ?
                                    setTimeout(() => {
                                        dispatch({type: 'IS_FOCUS', payload: false})
                                    }, 200) :
                                    onInputText.length > 1 && router.push(`/shop/?s=${onInputText}`)
                            }>
                        <Search/>
                    </button> */}

                    {/* <SearchSuggestion productData={productData} brandsData={brandsData} categoriesData={categoriesData}
                                  onInputText={onInputText} loading={loading}
                                  divRef={divRef} Is_Focus={Is_Focus}/> */}

export default SearchForVegitable;
