import React from 'react';
import {useSelector} from 'react-redux';
import {Btn} from '../../Components/AbstractElements';
import {PromotionalCatalog} from '../../Components/Constant';
import {selectAuth} from "../../ReduxToolkit/Slices/LoginSlice";
import {useRouter} from 'next/router';
import {Globe, User} from "react-feather";
import Link from "next/link";

const AllCategories = ({isCategories}) => {
    const auth = useSelector(selectAuth);
    const router = useRouter();

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <>
            <li className='category-menu onhover-dropdown wislist-dropdown' style={{marginLeft: "0"}}>
                <Btn
                    attrBtn={{
                        className: 'btn-solid-default toggle-id d-sm-block black-button',
                        onClick: () => {
                            !auth ?
                                openInNewTab("https://catalog.koaedi.com/cat/697643645781587/") :
                                openInNewTab("https://catalog.koaedi.com/cat/654465132687/")
                        },
                    }}>
                    {PromotionalCatalog.toUpperCase()}
                </Btn>
            </li>
            <li className='onhover-dropdown small-dropdown' >
                <Link href={'/contact-us/'}>
                    <div className='cart-media'>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M21 10c0 5.25-9 13-9 13S3 15.25 3 10a9 9 0 1 1 18 0z"></path>
                            <circle cx="12" cy="10" r="3"></circle>
                        </svg>


                    </div>
                </Link>
            </li>


        </>
    );
};

export default AllCategories;
