import React from 'react';

const NoProductFound = () => {
  return (
    <div className='search-suggestion'>
      <div id='no__found'>
        <span>No Data Found</span>
      </div>
    </div>
  );
};

export default NoProductFound;
