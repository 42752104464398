
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectAuth, selectAuthUser, selectOpenModal } from "../../../../ReduxToolkit/Slices/LoginSlice";
import AfterSignInAccount from "../../../../Components/Pages/UserDashboard/AfterSignInAccount ";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import BeforeSignInAccount from "../../../../Components/Pages/UserDashboard/BeforeSignInAccount";
import { ShoppingCart } from "react-feather";
import { toggleDivVisibility } from "../../../../ReduxToolkit/Slices/LoginSlice";

const LoggedUser = ({ isCardOpen, handleChangeOpenCard, handleToggleCard }) => {
  const [visible, setVisible] = useState(false)
  const { data } = useSelector(selectAuthUser);
  const open = useSelector(selectOpenModal);
  const auth = useSelector(selectAuth);
  // const [isCardOpen, setIsOpenCard] = useState(false);
  const menuRef = useRef(null);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          handleChangeOpenCard(false);
          dispatch(toggleDivVisibility(false))
        }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (open) {
      handleToggleCard();
    }
  }, [open]);

  useEffect(() => {
    if (isCardOpen && width < 768 || isCardOpen && auth) {
        document.body.style.overflow = 'hidden'; // Hide body overflow
    } else {
        document.body.style.overflow = 'visible'; // Restore body overflow
    }

}, [isCardOpen, auth]);

  // const handleToggleCard = () => {
  //   setIsOpenCard(prevState => !prevState)
  // }

  const handleChangeCardOpen = (bool) => {
    handleChangeOpenCard(bool)
  }
  
  const handleToggleMenu = () => {
    setVisible(prevState => !prevState)
  }

  useEffect(() => {
    // const hoverElement = document.querySelector('.onhover-dropdown');
    // if(visible) {
    //   hoverElement.classList.remove('onhover-dropdown');
    // } else {
    //   hoverElement.classList.add('.onhover-dropdown');
    // }
  }, [visible])

  return (
    <div className={`logged__user__wrapper ${auth && `onhover-dropdown account-dropbox cart-dropdown${isCardOpen ? !auth ? ' show-mobile' : " show" : ''}`}`}>
       {auth &&
            <BeforeSignInAccount isOpen={handleToggleCard} divRef={menuRef} setIsCartOpen={handleChangeCardOpen}/> 
          }
      <div style={{
        marginLeft: auth && 0,
      }} className="user__info__wrapper">
          {
            !auth ? <>
              <div onClick={handleToggleMenu}  className={`icons__wrapper onhover-dropdown account-dropbox cart-dropdown${isCardOpen ? !auth ? ' show-mobile' : " show" : ''}`}>
                {!auth && <AfterSignInAccount visible={visible} isOpen={handleToggleCard} divRef={menuRef} setIsCartOpen={handleChangeCardOpen}/>
                }
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="36" height="36" rx="18" fill="#B72021"/>
                  <path d="M12.7031 21.1406C14.5469 20.3906 16.3125 20.0156 18 20.0156C19.6875 20.0156 21.4375 20.3906 23.25 21.1406C25.0938 21.8594 26.0156 22.8125 26.0156 24V26.0156H9.98438V24C9.98438 22.8125 10.8906 21.8594 12.7031 21.1406ZM20.8125 16.8281C20.0312 17.6094 19.0938 18 18 18C16.9062 18 15.9688 17.6094 15.1875 16.8281C14.4062 16.0469 14.0156 15.1094 14.0156 14.0156C14.0156 12.9219 14.4062 11.9844 15.1875 11.2031C15.9688 10.3906 16.9062 9.98438 18 9.98438C19.0938 9.98438 20.0312 10.3906 20.8125 11.2031C21.5938 11.9844 21.9844 12.9219 21.9844 14.0156C21.9844 15.1094 21.5938 16.0469 20.8125 16.8281Z" fill="white"/>
                </svg>
                <p className="userName">Hi, {data?.name.split(' ')[0]}</p>
              </div>
            </> : <button onClick={handleToggleCard}>Login</button>
          }
        </div>
    </div>
  )
};

export default LoggedUser;